
import "./modal-v1"
import "./checkout-saved-multiple-payments-preview-v1"
import "./checkout-multiple-payments-v2"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutSavedMultiplePaymentsV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount () {
    // child elements
    this.paymentSelect = this.element.querySelector('[name="payment_method"]')
    this.paymentSelect.addEventListener('change', (evt) => {
      evt.preventDefault()
      const paymentId = evt.target.value == "new-payment" ? null : evt.target.value
      Checkout.store.payment.id.set(paymentId)
    })
    if (this.paymentSelect.value == 'new-payment') {
      const multiplePaymentsWrapper = this.element.querySelector('.elCheckoutMultiplePaymentsWrapper')
      multiplePaymentsWrapper.classList.remove('elHide')
    }
    // store listeners
    Checkout.computed.paymentMethod.listen(paymentMethod => {
      const multiplePaymentsWrapper = this.element.querySelector('.elCheckoutMultiplePaymentsWrapper')
      this.paymentMethod = paymentMethod
      if (paymentMethod) {
        multiplePaymentsWrapper.classList.add('elHide')
      } else {
        multiplePaymentsWrapper.classList.remove('elHide')
      }
    })
    Checkout.store.paymentMethods.listen((payment_methods) => {
      this.contact = {
        payment_methods
      }
      this.rerenderSelect()
    })
    Checkout.store.payment.state.listen((state) => {
      if (state == Checkout.PaymentStates.LOADING) {
        this.showLoader()
      } else if (state == Checkout.PaymentStates.INITIALIZED) {
        this.hideLoader()
      }
    })
  }

  showLoader() {
    this.element.querySelector('.elPAISavedLoader').dataset.loader = true
  }
  hideLoader() {
    this.element.querySelector('.elPAISavedLoader').dataset.loader = false
  }

  rerenderSelect () {
    const selectedId = Checkout.store.payment.id.get()
    this.paymentSelect.innerHTML = `
      ${this.contact.payment_methods.map(payment_method => {
        const checked = payment_method.id === selectedId
        const label = 
          payment_method.type === 'payment-card' ? payment_method.details : 
          payment_method.type === 'paypal' ? `Paypal - ${payment_method.details}` :
          ``
        return `<option value="${payment_method.id}" ${checked ? "checked" : ""}> ${label} </option>`          
      })}
      <option value="new-payment">Add new payment method</option>
    `
  }



}

registerComponent('CheckoutSavedMultiplePayments/V1', CheckoutSavedMultiplePaymentsV1)
window["CheckoutSavedMultiplePaymentsV1"] = CheckoutSavedMultiplePaymentsV1

