

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutSavedMultiplePaymentsPreviewV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    Checkout.computed.paymentMethod.subscribe((payment_method) => {      
      this.current_payment_method = payment_method || { type: 'new-payment' }
      this.render()
    })
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const current_payment_method = this.current_payment_method ?? null ;

      
      let html = '';
      {
        html += `  `
        if (current_payment_method?.type == "new-payment") {
          html += `<div class="elSelectText"> New Payment Method </div>`
        } else if (current_payment_method?.type == "payment-card") {
          html += `<div class="elSelectText"><i class="fab fa-cc-${current_payment_method?.icon}"></i><span class="elSelectTextMaskedNumber"> ${current_payment_method?.details}</span></div>`
        } else if (current_payment_method?.type == "paypal") {
          html += `<div class="elSelectText"><i class="fab fa-paypal"></i>${current_payment_method?.details}</div>`
        }

      }

      this.replaceContent(html) 

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

registerComponent('CheckoutSavedMultiplePaymentsPreview/V1', CheckoutSavedMultiplePaymentsPreviewV1)
window["CheckoutSavedMultiplePaymentsPreviewV1"] = CheckoutSavedMultiplePaymentsPreviewV1

