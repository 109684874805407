
 import  { computed } from "nanostores"

import "./input-v1"
import { IntlTel_initPhoneInput } from 'javascript/lander/cf_utils'
import { resetInputErrors, addError } from '@yggdrasil/Checkout/V2/Utils/checkout-input-validator'
import { CheckoutAuth } from '@yggdrasil/Checkout/V2/Services/checkout-auth'
import { isElementVisibleOnViewport } from '@yggdrasil/Utils/general'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutLoginV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}






  mount() {
    // mount subcomponents
    CheckoutAuth.initializeOneClickCheckoutFlow()
    this.mountPhoneVerificationSubform()

    // child elements
    this.firstNameInput = this.element.querySelector(".elCheckoutLogin_FirstName")    
    this.deviceInfo = this.element.querySelector(".elCheckoutLogin_DeviceInfo")
    this.alternateOtp = this.element.querySelector(".elCheckoutLogin_AlternateSource")
    this.alternateOtpDeviceInfo = this.element.querySelector(".elCheckoutLogin_AlternateDeviceInfo")
    const resendCodeEl = this.element.querySelector(".elResendCode");
    const continueAsGuest = this.element.querySelector(".elLinkContinueAsGuest");
     
    function getOtpSourceDescription (source) {
      const partialContact = Checkout.store.contact_pending_auth.get()
      if (!partialContact) return ''
      return (
        source === 'email' ? 'email address' :
        source === 'phone_number' ? `phone number ending in ${partialContact.masked_phone}` :
        source
      )
    }
    const otpSource = Checkout.auth.store.otpLoginSource
    
    const alternateOtpSource = computed([
      otpSource, 
      Checkout.auth.computed.otpLoginOptions
      ], (otpSource, otpOptions) => {
      return otpOptions.find(option => option !== otpSource)
    })
    
    this.bindText(this.firstNameInput, computed(Checkout.store.contact, contact => contact?.first_name))
    this.bindText(this.deviceInfo, otpSource, (otpSource) => {
      return getOtpSourceDescription(otpSource)
    })
    this.bindVisibility(this.alternateOtp, computed(alternateOtpSource, alt => !!alt))
    this.bindText(this.alternateOtpDeviceInfo, alternateOtpSource, getOtpSourceDescription)

    this.element.querySelector('[href="#change-otp-source"]').addEventListener('click', (ev) => {
      ev.preventDefault()
      const source = alternateOtpSource.get()
      CheckoutAuth.resendCode(source)
    })

    this.loginOtpInput = this.bindOtpInput(this.element.querySelector('.elCheckoutLoginInputContent .elOtpInput'), (code) => { CheckoutAuth.submitOtp(code) })
    this.loginOtpSubmit = this.element.querySelector('.elCheckoutLoginForm [href="#submit-otp-code"]')
    this.bindSubmitting(this.loginOtpSubmit.parentElement, CheckoutAuth.computed.submittingOtp)
    this.loginOtpSubmit.addEventListener('click', (ev) => {
      ev.preventDefault()
      this.loginOtpInput.submit()
    })
  
    this.checkoutLoginInfo = this.element.querySelector('.elOtpInfo p span');
    this.checkoutLoginError = this.element.querySelector('.elOtpError p span');
    this.checkoutLoginStatus = this.element.querySelector('.elOtpSendStatus p span');
    
    // event listeners
    this.element.querySelector(".elLinkResendCode")?.addEventListener("click", () => {
      this.setErrorMessage('')
      CheckoutAuth.resendCode()
    });
    continueAsGuest?.addEventListener("click", () => {
      if (Checkout.store.checkout.mode.get() !== 'guest') {
        Checkout.store.checkout.mode.set('guest')
      } else {
        // if Checkout already in guest mode, notify auth we are continuing as guest
        // this will keep the auth dialog from triggering if a new email is entered
        CheckoutAuth.continueAsGuest()
      }
    });

    // store listeners and bindings
    CheckoutAuth.computed.requireLogin.listen(requireLogin => {
      if (requireLogin) {
        this.show();
      } else {
        this.hide();
      }
    })
    CheckoutAuth.store.flow.listen((change) => {
      this.onFlowChange(change)
    })
    Checkout.store.state.listen((state) => {
      if (state === Checkout.StoreStates.INITIALIZED) {
        this.initializePhoneNumber()
      }
    })
    Checkout.store.checkout.mode.listen((state) => {
      if (state === 'saved') {
        this.initializePhoneNumber()
      }
    })
    this.bindText(this.checkoutLoginInfo, CheckoutAuth.computed.requestingAnotherOtp, (active) => {
      return active ? `Requesting a new code...` : ``
    })
    this.bindText(this.element.querySelector('.elResendOtpWaitTime'), CheckoutAuth.computed.retrySeconds, seconds => seconds.toString())
    this.bindVisibility(resendCodeEl, CheckoutAuth.computed.retrySeconds, seconds => !(seconds > 0))
    this.bindVisibility(this.element.querySelector('.elWaitToResendOtp'), CheckoutAuth.computed.retrySeconds, seconds => seconds > 0)
    CheckoutAuth.store.otpRequestFailure.subscribe(({ error }) => {
      if (error) {
        this.notifyNetworkError(error)
      }
    })
    this.bindVisibility(this.element.querySelector('.elCheckoutLoginForm'), CheckoutAuth.computed.suggestingPhoneVerification, false)
    this.bindVisibility(this.element.querySelector('.elCheckoutPhoneVerificationContainer'), CheckoutAuth.computed.suggestingPhoneVerification)
    this.bindVisibility(this.element.querySelector('.elCheckoutPhoneVerificationOtpInputContent'), CheckoutAuth.computed.showingPhoneOtp)    
  }
  mountPhoneVerificationSubform () {
    // find and bind child elements
    this.phoneVerificationOtpInput = this.bindOtpInput(
      this.element.querySelector(".elCheckoutPhoneVerificationOtpInputContent .elOtpInput"),
      code => CheckoutAuth.submitVerifyPhoneCode(code)
    )
    this.phoneVerificationOtpSubmit = this.element.querySelector('.elCheckoutPhoneVerificationForm [href="#submit-otp-code"]')
    this.bindSubmitting(this.phoneVerificationOtpSubmit.parentElement, CheckoutAuth.computed.submittingPhoneVerification)
    this.phoneVerificationOtpSubmit.addEventListener('click', (ev) => {
      ev.preventDefault()
      this.phoneVerificationOtpInput.submit()
    })
    // event handlers
    this.element.querySelector('.elContinueAfterPhoneVerification').addEventListener('click', evt => {
      evt.preventDefault()
      CheckoutAuth.continueAfterPhoneVerification()
    })
    Array.from(this.element.querySelectorAll('.elSkipPhoneVerification')).forEach(el => {
      el.addEventListener('click', ev => {
        ev.preventDefault();
        CheckoutAuth.skipPhoneVerification()      
      })
    })

    this.element.querySelector('.elVerifyPhoneButton > a').addEventListener('click', ev => {
      ev.preventDefault()
      CheckoutAuth.requestPhoneVerification()
    })
    this.bindSubmitting(this.element.querySelector('.elVerifyPhoneButton'), CheckoutAuth.computed.RequestingPhoneVerification)

    this.element.querySelector('.elLinkRerequestPhoneVerification').addEventListener('click', ev => {
      ev.preventDefault()
      // TODO: include TRIES
      CheckoutAuth.requestPhoneVerification()
    })
    // store listeners
    CheckoutAuth.computed.phoneVerificationRequestErrorMsg.subscribe(msg => {
      $(this.element.querySelector('.elPhoneVerificationRequestError')).text(msg)
    })    
    CheckoutAuth.computed.phoneVerificationErrorMsg.subscribe(msg => {
      $(this.element.querySelector('.elPhoneVerificationOtpError')).text(msg)
      if (msg) {
        this.phoneVerificationOtpInput.clear()
        this.phoneVerificationOtpInput.focus()                
      }
    })
    this.bindText(this.element.querySelector('.elPhoneVerificationMessages'), CheckoutAuth.computed.phoneVerificationMessages)

    // TODO: detect tries?
    CheckoutAuth.computed.RequestingPhoneVerification.subscribe(pending => {
      this.element.querySelector('.elLinkRerequestPhoneVerification').href = pending ? '' : '#'
      if (pending === false) {
        this.phoneVerificationOtpInput.focus()
      }
    })
    this.bindVisibility(this.element.querySelector('.elCheckoutPhoneVerificationForm'), CheckoutAuth.computed.showingPhoneOtp)
    this.bindVisibility(this.element.querySelector('.elPhoneVerificationRequest'), CheckoutAuth.computed.showingPhoneVerificationRequest)
    
  }  
  show() {
    const { first_name, masked_phone, is_phone_verified } = Checkout.store.contact_pending_auth.get()
    const checkoutElement = this.element.closest('.elCheckout')
    this.element.classList.remove('hide')
    this.resetLoginOtpInputs()
    this.resetMessages()
    if (isElementVisibleOnViewport(checkoutElement, 20)) this.loginOtpInput.focus()
  }
  hide() {
    this.element.classList.add('hide')
  }

  setElText(el, text) {
    jQuery(el).text(text)
  }
  bindText(el, store, fn) {
    return store.subscribe(value => {
      const text = fn ? fn(value) : value
      this.setElText(el, text?.toString() || '')
    })
  }
  bindVisibility(el, store, test) {
    return store.subscribe(value => {
      let visible = 
        test!==undefined ? 
          typeof test === 'function' ? 
            test(value) 
          : test === value
        : value  
      el.hidden = !visible
    })
  }
  onInvalidOtp() {
    this.setErrorMessage("You entered an invalid code. Try again or resend the code by clicking the link below.")
    this.resetLoginOtpInputs()
  }
  onResentCode() {
    this.loginOtpInput.focus()
  }
  notifyNetworkError(error) {
    this.setErrorMessage('Unable to send new verification code due to a network error. Check your connection and try again later.')    
  }
  // TODO: factor out depencency on event and state strings
  // use computeds and prefer matching states to events
  onFlowChange({ event, from, to }) {
    if (event === 'submitOtp') {
      this.onSubmittingOtp()
    }
    if (event === 'resentOtp') {
      this.onResentCode()
    }
    if (event === 'invalidOtp') {
      this.onInvalidOtp()
    }
    if (event === 'resendFailed') {
      const { error, retry_in } = to
      if (error) {
        this.notifyNetworkError()
      } else if (retry_in) {
        this.loginOtpInput.focus()
      }
    }
    if (event === 'verificationSucceeded') {
      this.element.querySelector('.elCheckoutPhoneVerificationConfirmation').hidden = false
      this.element.querySelector('.elCheckoutPhoneVerificationForm').hidden = true   
    }
  }

  bindOtpInput(input, onSubmit) {
    const maxLength = Number(input.getAttribute('maxlength'))
    const getCode = () => {
      return input.value
    }
    const submitIfValid = () => {
      const code = getCode();
      if (code.length === maxLength) {
        onSubmit(code)        
      }
    }

    input.addEventListener('keydown', (ev) => {
      if (event.key === 'Tab') {
        return
      }
      if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
        submitIfValid()
        return 
      }
      if (event.key === "Backspace" || event.keycode === 37) {
        if (input.value.length === maxLength) {
          submitIfValid();
        }
      }
    })

    input.addEventListener('input', () => {
      input.style.setProperty('--_otp-digit', input.selectionStart)
      if (input.value.length === maxLength) {
        submitIfValid();
      }
    });

    function setEnabled(enabled) {
      input.disabled = !enabled
    }
    function focus(index = 0) {
      input.style.setProperty('--_otp-digit', index)
      input.focus()
    }
    function clear() {
      input.style.setProperty('--_otp-digit', 0)
      input.value = ''
    }
    return { input, getCode, setEnabled, clear, focus, submit: submitIfValid }  
  }

  onSubmittingOtp() {
    this.resetMessages()    
  }
  
  resetLoginOtpInputs () {
    this.loginOtpInput.clear()
    const checkoutElement = this.element.closest('.elCheckout')
    if (isElementVisibleOnViewport(checkoutElement, 20)) this.loginOtpInput.focus()
  }  

// maybe keep
  resetMessages() {
    this.setErrorMessage('')
  }
  setErrorMessage(message) {
    // move    
    this.checkoutLoginError.innerHTML = message;
  }
  bindLoader(el, store, test) {
    return store.subscribe(value => {
      let visible = 
        test!==undefined ? 
          typeof test === 'function' ? 
            test(value) 
          : test === value
        : value  
      if (visible) { el.dataset.loader = true }
      else { delete el.dataset.loader }
    })
  }
  bindSubmitting (button, store) {
    const elButton = button.querySelector('.elButton')
    const buttonMain = button.querySelector('.elButtonMain')
    const buttonMainText = buttonMain.querySelector('.elButtonMainText')
    const spinner = buttonMain.querySelector('.elButtonSpinner')
    const buttonText = buttonMainText.innerHTML
    const submittingText = elButton.getAttribute('data-param-submittingtext') || 'Submitting...'
    store.listen((submitting) => {
      if (submitting) {
        spinner.style.display = 'inline-block'        
        buttonMainText.innerHTML = submittingText
      }
      else {
        spinner.style.removeProperty('display');        
        buttonMainText.innerHTML = buttonText
        button.querySelector('.elButtonSub').innerHTML = ''        
      }      
    })    
  }
  bindDisabled(el, store, test) {
    return store.subscribe(value => {
      let disabled = 
        test!==undefined ? 
          typeof test === 'function' ? 
            test(value) 
          : test === value
        : value  
      el.disabled = disabled
    })
  }
  
  // #region phone number form
  // ported from contact-form-v1

  getInputFromName(name) {
     return this.element.querySelector(`[name='${name}']`)
  }

  // TODO: The intlTelInput setup should be handled by the Input/V1 blueprint
  initializePhoneNumber() {   
    const phoneInput = this.getInputFromName('phone_number')
    const initialCountry = Checkout.store.shipping.get().country    
    IntlTel_initPhoneInput(phoneInput, { initialCountry })
    
    // bind event listeners
    phoneInput.addEventListener("countrychange", () => {
      Checkout.store.contact.setKey('phone_number', phoneInput.iti.getNumber())
    });    
    phoneInput.addEventListener('blur', () => {
      const value = phoneInput.iti.getNumber()
      Checkout.store.contact.setKey('phone_number', value)
    })

    // computed
    const $phoneNumber = computed(Checkout.store.contact, ({ phone_number }) => phone_number)
    $phoneNumber.subscribe(phone_number => {
      phoneInput.iti.setNumber(phone_number || '')
      if (phone_number) {
        $(phoneInput).parents('.elFormItemWrapper').addClass('hasValue')
      } else {
        $(phoneInput).parents('.elFormItemWrapper').removeClass('hasValue')
      }
    })
    
    const $phoneError = computed(Checkout.computed.contactErrors, (errors) => {
      this.checkForm(Checkout.computed.contactErrors.get())   
      return errors?.fields?.phone_number
    })    
    const $disableSubmit = computed([$phoneNumber, $phoneError], (phone_number, error) => {
      return !phone_number || error
    })
    // store listeners
    Checkout.computed.contactErrors.subscribe(contactErrors => {
      this.checkForm(contactErrors) 
    })
    $disableSubmit.subscribe(disable => {      
      this.element.querySelector('.elVerifyPhoneButton > a').dataset.disabled = disable
    })
  }  
  checkForm(contactErrors) {
    // we only care about phone errors
    const fields = ['phone_number']
    // reset field errors
    fields.forEach((prop) => {
      const input = this.getInputFromName(prop)
      resetInputErrors(input)
    })
    // check, show and set field errors
    if (Checkout.utils.hasErrors(contactErrors)) {
      const fieldErrors = contactErrors.fields
      if (fieldErrors) {
        fields.forEach((field) => {
          const error = fieldErrors[field]
          if (error) {
            const {message} = error
            const input = this.getInputFromName(field)
            addError(input, message)
          }
        })
      }
    }
  } 
  // #endregion phone number form



}

registerComponent('CheckoutLogin/V2', CheckoutLoginV2)
window["CheckoutLoginV2"] = CheckoutLoginV2

