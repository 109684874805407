
import "./checkout-address-form-v1"
import "./radio-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutSavedAddressFormV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.bindNewAddressClick()
    this.bindAddressClick()

    Checkout.store[`${this.type}_addresses`].listen(addresses => {
      this.addresses = addresses
      if (addresses && addresses.length > 0) {
        this.addresses = addresses
        this.renderAddresses(addresses) 
        this.getAddNewAddressButton().classList.remove('elHide')
        this.getAddNewAddressElement().classList.remove('elCheckoutStandaloneSavedAddNewAddress')
        this.getAddNewAddressElement().classList.add('elHide')
      } else {
        this.getAddressInputByValue('new-address').checked = true
        this.showAddNewAddressForm()
      }
    })
  }
  bindNewAddressClick() {
    this.getAddNewAddressButton().addEventListener('click', (evt) => {
      evt.preventDefault()
      this.getAddressInputByValue('new-address').checked = true
      this.showAddNewAddressForm()
    })
  }
  getAddressFormValues() {
    return this.getComponent('CheckoutAddressForm/V1').store.get()
  }
  validateNewAddressForm() {
    return this.getComponent('CheckoutAddressForm/V1').validateFormFields()
  }
  showAddNewAddressForm() {
    this.getAddNewAddressButton().classList.add('elHide')
    this.getAddNewAddressElement().classList.remove('elHide')
  }
  getAddNewAddressElement() {
    return this.element.querySelector('.elCheckoutSavedAddNewAddress')
  }
  getAddNewAddressButton() {
    return this.element.querySelector('.elCheckoutSavedAddNewAddressContainer')
  }
  getAddressInputByValue(value) {
    return this.element.querySelector(`input[value="${value}"]`)
  }
  getSavedAddressById(id) {
    return this.addresses.find((addr) => addr.id == id)
  }
  getSelectedAddressId() {
    return this.element.querySelector(`input[name="selected-${this.type}-address"]:checked`).value
  }
  isNewAddress() {
    return this.getSelectedAddressId() === 'new-address'
  }
  bindAddressClick () {
    Array.from(
      this.element
        .querySelector('.elCheckoutAddressList')
        .querySelectorAll('.elCheckoutSavedAddressContainer')).forEach(addressContainer => {
          const elRadio = addressContainer.parentElement.querySelector('[type="radio"]')
          addressContainer.addEventListener('click', ev => {
              ev.preventDefault()
              elRadio.checked = true
            })
          })
  }
  renderAddresses (addresses=[]) {
    if (addresses.length === 0) return     
    
    const el = this.element.querySelector('.elCheckoutAddressList')
    $(el).html(`
      ${addresses.map((address, index) => {
        const { id, city, state, zip, country } = address
        const { type } = this
        const checked = index === 0
        return `
          <div class="elCheckoutSavedAddressContainer">

            <div data-page-element="Radio/V1" class="elRadioWrapper">
              <label class="elRadioLabel">
                <input type="radio" value="${address.id}" name="selected-${type}-address" ${checked ? 'checked' : ''} class="elRadioInput">
                <div class="elRadio">
                  <div class="elRadioIcon">
                  </div>
                </div>
                <span class="elRadioText"></span>
              </label>
            </div>
            <div class="elCheckoutSavedAddress"> 
              ${ !address.address ? '' : `
                <span class="elCheckoutSavedAddressInfo">
                  ${[address.address, address.address2].filter(x =>x).join(', ')}
                </span>
              `}
              <span class="elCheckoutSavedAddressDetails">
                ${[city, state, zip, country].filter(x => x).join(', ')}
              </span>
            </div>
          </div>        
        `
      }).join('\n')}
    `)
    this.bindAddressClick()    
  }



}

registerComponent('CheckoutSavedAddressForm/V1', CheckoutSavedAddressFormV1)
window["CheckoutSavedAddressFormV1"] = CheckoutSavedAddressFormV1

