import { CheckoutSummary } from './checkout-summary'
import { CheckoutSubmit } from './checkout-submit'
import { UpdateTransactionData } from './checkout-submit'
import { Address, CheckoutCart, ShippingOption } from 'src/Elements/Checkout/V2/types'
import { hasPhysicalProducts } from './checkout-utils'
export class CheckoutDigitalWallet {
  static transactionShipping
  static transactionShippingOption
  static transactionBilling
  static shippingOptions
  static initialize(): void {
    globalThis.Rebilly.on('token-ready', (data, extraData) => {
      const paymentType = globalThis.Checkout.store.payment.type.get()
      const paymentMethod = data.method
      const paymentInstrumentMethod = data.paymentInstrument?.type
      CheckoutSummary.skipOrderSummaryUpdateWithCb(() => {
        if (paymentMethod == 'digital-wallet' && paymentInstrumentMethod == 'Apple Pay') {
          let rebillyShippingAddress, rebillyBillingAddress
          const shippingAddress = extraData?.shippingDetails?.address
          const billingAddress = data.billingAddress
          const { emailAddress: shippingEmail, familyName: shippingLN, givenName: shippingFN } = shippingAddress ?? {}
          const {
            firstName: billingFN,
            lastName: billingLN,
            // address: billingAddr,
            // address2: billingAddr2,
          } = billingAddress

          if (globalThis.Checkout.computed.hasPhysicalProducts.get()) {
            rebillyShippingAddress = {
              address: shippingAddress.addressLines?.[0],
              address_2: shippingAddress.addressLines?.[1],
              country: shippingAddress.countryCode.toUpperCase(),
              city: shippingAddress.locality,
              zip: shippingAddress.postalCode,
              state: shippingAddress.administrativeArea,
            }
          }
          rebillyBillingAddress = {
            address: billingAddress.address,
            address_2: billingAddress.address2,
            country: billingAddress.country.toUpperCase(),
            city: billingAddress.city,
            zip: billingAddress.postalCode,
            state: billingAddress.region,
          }
          let contact
          if (globalThis.Checkout.computed.useDigitalWalletForUpdatingContactStore.get()) {
            contact = {
              email: shippingEmail,
              first_name: [shippingFN, billingFN].find(Boolean),
              last_name: [shippingLN, billingLN].find(Boolean),
              phone_number: globalThis.Checkout.store.contact.get().phone_number,
            }
          }
          const payload = CheckoutSubmit.buildPayloadFromStore({
            contact: contact ?? globalThis.Checkout.store.contact.get(),
            turnstileToken: globalThis.Checkout.turnstileToken,
            shippingAddress: rebillyShippingAddress,
            billingAddress: rebillyBillingAddress,
            paymentMethodDetails: {
              payment_method_id: null,
              payment_method_type: 'apple-pay',
              rebilly_token: data.id,
            },
            selectedShippingOption: this.transactionShippingOption ?? {},
            skipBillingAddress: false,
          })
          CheckoutSubmit.submit(payload)
        } else {
          globalThis.Checkout.store.payment[paymentType].token.set(data.id)
        }
      })
    })
    globalThis.Rebilly.on('shipping-address-changed', (shippingAddress, updateShippingOptions) => {
      this.transactionShipping = {
        address: shippingAddress.addressLines?.[0],
        address_2: shippingAddress.addressLines?.[1],
        country: shippingAddress.countryCode.toUpperCase(),
        city: shippingAddress.locality,
        zip: shippingAddress.postalCode,
        state: shippingAddress.administrativeArea,
      }
      this.#updateRebillyWithCallback(updateShippingOptions)
    })
    globalThis.Rebilly.on('shipping-option-changed', (selectedShippingOption, updateTransaction) => {
      CheckoutSummary.skipOrderSummaryUpdateWithCb(() => {
        this.transactionShippingOption = this.shippingOptions.find(
          (s) => CheckoutSubmit.generateShippingOptionId(s) == selectedShippingOption.id
        )
      })
      this.#updateRebillyWithCallback(updateTransaction)
    })
    globalThis.Rebilly.on('billing-address-changed', (billingAddress, updateTransaction) => {
      billingAddress &&
        (this.transactionBilling = {
          address: billingAddress.addressLines?.[0],
          address_2: billingAddress.addressLines?.[1],
          country: billingAddress.countryCode.toUpperCase(),
          city: billingAddress.locality,
          zip: billingAddress.postalCode,
          state: billingAddress.administrativeArea,
        })
      this.#updateRebillyWithCallback(updateTransaction)
    })
  }

  static #updateRebillyWithCallback(updateCallback: (transactionData: UpdateTransactionData) => void): void {
    const cart = globalThis.Checkout.computed.checkoutCart.get() as CheckoutCart
    const shippingEnabled = hasPhysicalProducts(cart)
    CheckoutSummary.fetchOrderSummaryForExpress(cart, {
      billingAddress: this.transactionBilling ?? ({} as Address),
      shippingAddress: this.transactionShipping ?? (shippingEnabled ? this.transactionBilling : {}) ?? ({} as Address),
      shippingOption: this.transactionShippingOption ?? ({} as ShippingOption),
      billingSameAsShipping: false,
    }).then(({ summary, data }) => {
      const { shipping_quotes_response } = summary
      this.shippingOptions = shipping_quotes_response?.options ?? []
      const transactionData = CheckoutSubmit.buildDigitalWalletTransactionData(
        data,
        cart,
        shippingEnabled,
        this.shippingOptions
      )
      updateCallback(transactionData)
    })
  }
}
