
import "./checkout-address-form-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutShippingAddressV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.contentEl = this.element.querySelector('div')

    const shouldDisplay = nanostores.computed([
      Checkout.computed.isNewDigitalWalletPayment,
      Checkout.computed.hasPhysicalProducts
    ], (isNewDigitalWalletPayment, hasPhysicalProducts) => {
      return hasPhysicalProducts && !isNewDigitalWalletPayment
    })

    shouldDisplay.subscribe(display => {
      if (display) {
        this.show()
      } else {
        this.hide()
      }
    })
  }
  show() {
    this.element.classList.remove('elHide');
  }
  hide() {
    this.element.classList.add('elHide');
  }



}

registerComponent('CheckoutShippingAddress/V1', CheckoutShippingAddressV1)
window["CheckoutShippingAddressV1"] = CheckoutShippingAddressV1

